import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'hookrouter'
import React, { useState, useEffect } from 'react'
import { connectAPI } from '../helper/api'
import { Jour } from './Jour'

export const Horaires =()=> {

    useEffect(() => {
        getHoraire();
    },[])

    const [spinnerVisible, setspinnerVisible] = useState("d-none")
    const [horaire, sethoraire] = useState([])

    const updateHoraire = (index, jour) => 
    {
        let newArr = [...horaire]; 
        newArr[index] = jour 
        sethoraire(newArr) 
    }

    const handleSauvergaderClic = ()=>
    {
        setspinnerVisible("d-inline")
        setHoraire()
    }

    const getHoraire = async ()=>{
        let data = new FormData()
        data.append("req", "get")
        
        const result = await connectAPI("horaire.php", data)
        sethoraire(result.data)
    }

    const setHoraire = async ()=>
    {
        setspinnerVisible("d-inline")

        let data = new FormData()
        data.append("req", "set")
        data.append('horaire', JSON.stringify(horaire));
        
        const result = await connectAPI("horaire.php", data)
        if(result.data == true)
            alert("Horaire mis à jour avec succès")
        else   
            alert("Une erreur s'est produite. Actualisez la page et recommencez")

        setspinnerVisible("d-none")
    }
    if(sessionStorage.getItem("token") == null)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/')

        return null
    }
    else
    {
        return (
            <div className="container p-3">
                <div className="mt-3">
                    <h3 className="text-center">Heures d'ouverture</h3>
                </div>
                
                <div className="row mt-3">
                    <div className="col-6 mx-auto bg-white shadow p-5">
                        <p className="text-center mb-4">Sélectionnez les jours et les heures d'ouverture</p>
                        
                        {
                            horaire.map( (jour, index) => (
                                <Jour key={jour.jour}  jour={jour} fonction={updateHoraire}  position={index} />           
                                )
                            )
                        }
                        <div className="text-center">
                            <button className="btn btn-lg primaire text-white" onClick={handleSauvergaderClic}>Sauvegarder</button>
                        </div>
                        <div className="col-12 text-center">
                            <FontAwesomeIcon className={spinnerVisible} icon={faSpinner} spin size="2x" />
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
