import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'hookrouter'
import React, { useState } from 'react'
import { connectAPI } from '../helper/api'

export const Securite = ()=> {

    const [ancienMDP, setAncienMDP] = useState("")
    const [nouveauMDP, setNouveauMDP] = useState("")
    const [confirmationMDP, setConfirmationMDP] = useState("")

    const [erreurAncienMDP, seterreurAncienMDP] = useState("")
    const [erreurNouveauMDP, seterreurNouveauMDP] = useState("")
    const [erreurConfirmationMDP, seterreurConfirmationMDP] = useState("")

    const [resultatModif, setResultatModif] = useState("")
    const [couleurResultat, setCouleurResultat] = useState("")

    const [visible, setvisible] = useState("d-none")

    const erreursInitiales = () =>{
        seterreurAncienMDP("")
        seterreurConfirmationMDP("")
        seterreurNouveauMDP("")
    }

    const handleAncienMDPChange=(event)=>{
        setAncienMDP(event.target.value)
    }

    const handleNouveauMDPChange=(event)=>{
        setNouveauMDP(event.target.value)
    }

    const handleConfirmationMDPChange=(event)=>{
        setConfirmationMDP(event.target.value)
    }

    const handleSubmit = event=>{
        event.preventDefault()
        erreursInitiales()

        let mdpregex = "^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).*$"
        let valid = true

        if(ancienMDP.trim() == "" )
        {
            seterreurAncienMDP("Entrez l'ancien mot de passe")
            valid = false
        }

        if(nouveauMDP.trim() == "" )
        {
            seterreurNouveauMDP("Entrez le nouveau mot de passe")
            valid = false
        }
        else if(!nouveauMDP.trim().match(mdpregex))
        {
            let erreur = "Le mot de passe doit comprendre: "
            erreur += " - au moins 8 caractères"
            erreur += " - une lette minuscule"
            erreur += " - une lette majuscule"
            erreur += " - un chiffre"

            seterreurNouveauMDP(erreur)
            valid = false
        }


        
        if(nouveauMDP.trim() !== confirmationMDP.trim() )
        {
            seterreurConfirmationMDP('Les mots de passe doivent être identiques')
            valid = false
        }
        
        if(valid == true)
        {
            //setvisible("d-block")
            updateMDP()
        }

        
    }

    const updateMDP = async () =>{
        let data = new FormData()
        data.append('req', "update")
        data.append('ancien', ancienMDP)
        data.append('nouveau', nouveauMDP)
        data.append('token', sessionStorage.getItem("token"))

        const result = await connectAPI("connexion.php", data)

        //setvisible("d-none")
        if(result.data == true)
        {
            setResultatModif("Modifications réussies")
            setCouleurResultat("text-success")
            setAncienMDP("")
            setNouveauMDP("")
            setConfirmationMDP("")
            window.open("http://localhost:3000/securite","_self")
        }
        else
        {
            setResultatModif("Le mot de passe ne correspond pas.")
            setCouleurResultat("text-danger")
        }
    }

    if(sessionStorage.getItem("token") == null)
    {
        navigate('/')

        return null
    }
    else
    {
        return (
            <div className="container p-3">
                <div className="mt-3">
                    <h3 className="text-center">Gestion du mot de passe</h3>
                </div>
                
                <div className="row mt-3">
                    <div className="col-6 mx-auto bg-white shadow p-5">
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="row">
                                <div className="col-1 mx-auto text-center">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" className={visible + " text-center"} />
                                </div>
                            </div>
                            <div className={"text-center " + couleurResultat}  >
                                <h5>{resultatModif}</h5>
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="idMDP" className="form-label fs-5 ">Ancien mot de passe *</label>
                                <input type="password" className="form-control" onChange={handleAncienMDPChange} required value={ancienMDP} aria-label="Mot de passe" aria-describedby="idMDP" />
                                <div className="text-start form-text col-12 text-danger">{erreurAncienMDP}</div>
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="idMDP" className="form-label fs-5 ">Nouveau mot de passe *</label>
                                <input type="password" className="form-control" onChange={handleNouveauMDPChange} required value={nouveauMDP} aria-label="Mot de passe" aria-describedby="idMDP" />
                                <div className="text-start form-text col-12 text-danger">{erreurNouveauMDP}</div>
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="idConfirmation" className="form-label fs-5">Confirmez le mot de passe *</label>
                                <input type="password" className="form-control"  onChange={handleConfirmationMDPChange} required value={confirmationMDP} aria-label="Confirmer" aria-describedby="idConfirmation" />
                                <div className="text-start form-text col-12 text-danger">{erreurConfirmationMDP}</div>
                            </div>
                            <div className=" mb-3 text-center">
                                <button type="submit" id="envoyer" className='btn primaire text-white btn-lg col-12 col-md-6'>Modifier</button>
                            </div>

                        </form>
                    </div>
                    
                </div>
            </div>
        )
    }
}
