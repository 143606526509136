import logo from './logo.svg';
import './App.css';
import { SideMenu } from './composants/SideMenu';
import { Erreur } from './composants/Erreur'
import { Horaires } from './composants/Horaires'
import { Annonce } from './composants/Annonce'
import { useRoutes } from 'hookrouter';
import { Connexion } from './composants/Connexion';
import { Securite } from './composants/Securite';

const routes = {
  '/': () => <Connexion />,
  '/erreur': () => <Erreur />,
  '/horaire': () => <Horaires />,
  '/annonce': () => <Annonce />,
  '/securite': () => <Securite />
}
function App() {
    document.title = 'Livres-AGECTR - admin'
    const match = useRoutes(routes)

    if(match == null)
        return <Erreur />  
    else if(match.type.name == "Connexion")
      return <Connexion />
    else if(match.type.name == "E")
      return <Connexion />
    else
    {
      return (
        <div className="d-flex">
           <SideMenu />
           { match || <Erreur />}
        </div>
        
      )
    }
}

export default App;

//"homepage": "http://admin-public-livres-usages.agectr.com/"
