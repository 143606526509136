import { faLock, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../img/logo_age_600px.png'
import React, { useState } from 'react'
import { connectAPI } from '../helper/api'
import { navigate } from 'hookrouter'

export const Connexion = ()=> {

    const [user, setuser] = useState("")
    const [mdp, setmdp] = useState("")
    const [messageErreur, setmessageErreur] = useState("")
    const [spinnerVisible, setspinnerVisible] = useState("d-none")

    const handleUserChange = event =>{
        setuser(event.target.value)
    }

    const handleMDPChange = event =>{
        setmdp(event.target.value)
    }

    const connexion = async event =>{
        setmessageErreur("")
        event.preventDefault()

        setspinnerVisible("d-inline")
        let data = new FormData()
        data.append("req", "login")
        data.append('user', user);
        data.append('mdp', mdp);
        
        const result = await connectAPI("connexion.php", data)
        setspinnerVisible("d-none")

        if(result.data != null)
        {
            sessionStorage.setItem("token", result.data)
            navigate("/annonce")
        }
        else
        {
            setmessageErreur("Nom d'utilisateur ou mot de passe incorrect.")
        }
            
        
        
    }


    return (
        <div className="container-fluid p-5">
            <div className="row">
                <div className="col-12 text-center">
                    <img src={logo} alt="logo AGECTR" className="img-fluid" width="150px" />
                </div>
                <div className="col-12 text-center mt-3">
                    <h5>Gestion du site <a href="http://livres-usages.agectr.com/">livres-usagés.agectr.com</a></h5>
                </div>
            </div>
            <div className="row">
                <div className="col-4 mx-auto m-5 p-3 shadow bg-white">
                    <h2 className="text-center mb-4">Connexion</h2>
                    <p className="text-danger text-center">{messageErreur}</p>
                    <form onSubmit={connexion}>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="">
                                <FontAwesomeIcon icon={faUser} className="texte-primaire" size="1x" />
                            </span>
                            <input type="text" className="form-control" placeholder=""  required  onChange={handleUserChange}/>
                            <div className="text-start form-text col-12 text-warning"></div>
                        </div>
                        <div className="input-group mb-4">
                            <span className="input-group-text" id="basic-addon2">
                                <FontAwesomeIcon icon={faLock} className="texte-primaire" size="1x" />
                            </span>
                            <input type="password" className="form-control" placeholder="" required onChange={handleMDPChange}/>
                            <div className="form-text text-start col-12 text-warning"></div>
                        </div>
                            
                        <div className="mb-1  text-center">
                            <button type="submit" id="envoyer" className='btn primaire text-white btn-lg col-12' >Se connecter</button>
                        </div>
                        <div className="col-12 text-center">
                            <FontAwesomeIcon className={spinnerVisible} icon={faSpinner} spin size="2x" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
