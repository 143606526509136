import { faBullhorn, faClock, faExclamationCircle, faHome, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { A } from 'hookrouter'
import { navigate } from 'hookrouter'
import React, { useEffect, useState } from 'react'
import logo from '../img/logo_age_600px.png'

export const SideMenu = ()=> {
    const [currentMenu, setcurrentMenu] = useState("annonce")

    const handleSideMenuClic = event =>{
        //event.preventDefault()

        //prend le menu précédent et lui enlève la classe "active"
        let precedent = document.getElementById(currentMenu)
        precedent.classList.remove('actif')

        let id = event.target.getAttribute("id")
        let courant = document.getElementById(id)
        courant.classList.add('actif');

        setcurrentMenu(id)
        navigate("/" + id)
        
    }

    const deconnexion = ()=>{
        if(window.confirm("Voulez-vous vous déconnecter ?"))
        {
            sessionStorage.removeItem("token")
            navigate("/")
        }
    }

    return (
        <div>
            <div className="d-flex flex-column p-3 text-white bg-white sidemenu" >
                <div className="text-center">
                    <img src={logo} className="img-fluid logo-sidemu" />
                    <h4 className="texte-primaire">
                        Livres-usagés <br />
                        <small>admin</small>
                    </h4>
                </div>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <A href="/annonce" className="nav-link texte-secondaire actif" id="annonce" onClick={handleSideMenuClic}>
                            <FontAwesomeIcon icon={faBullhorn} size="1x" className="mr-4" />
                            Annonce
                        </A>
                    </li>
                    <li >
                        <A href="/horaire" className="nav-link texte-secondaire "  id="horaire" onClick={handleSideMenuClic}>
                            <FontAwesomeIcon icon={faClock} size="1x" className="mr-4"/>
                            Horaire
                        </A>
                    </li>
                    <li >
                        <A href="/securite" className="nav-link texte-secondaire "  id="securite" onClick={handleSideMenuClic}>
                            <FontAwesomeIcon icon={faLock} size="1x" className="mr-4"/>
                            Sécurité
                        </A>
                    </li>
                </ul>
                <div className="mt-5 text-center">
                    <button className="btn btn-danger" onClick={deconnexion}>Déconnexion</button>
                </div>
            </div>
        </div>
    )
}
