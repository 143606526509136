import React, { useState } from 'react'
import { useEffect } from 'react'

export const Jour = props=> {
    
   //console.log(props.jour.debut);
    const [jour, setjour] = useState(props.jour)
    const [ouvert, setouvert] = useState(props.jour.ouvert == 1 ? true:false)
    const [debut, setdebut] = useState(props.jour.debut)
    const [fin, setfin] = useState(props.jour.fin)
    console.log(ouvert);
    useEffect(()=>{
        let nJour = jour
        nJour.debut = debut
        setjour(nJour)
        props.fonction(props.position, jour)
    }, [debut])

    useEffect(()=>{
        let nJour = jour
        nJour.fin = fin
        setjour(nJour)
        props.fonction(props.position, jour)
    }, [fin])

    useEffect(()=>{
        let nJour = jour
        nJour.ouvert = ouvert
        setjour(nJour)
        props.fonction(props.position, jour)
    }, [ouvert])

    

    const handleOuvertChange = event =>{
        setouvert(event.target.checked)
    }

    const handleDebutChange = event =>{
        setdebut(event.target.value)
    }

    const handleFinChange = event =>{
        setfin(event.target.value)
    }

    return (

        <div className="row mb-3">
            <div className="col-5">
                <div className="form-check ">
                    <input type="checkbox" className="form-check-input" checked={ouvert} onChange={handleOuvertChange}/>
                    <label className="form-check-label" >
                        <h4>{jour.jour}</h4>
                    </label>
                </div>
            </div>
            <div className="col">
                <select className="form-control" onChange={handleDebutChange} value={debut}>
                    <option value="07H00">07H00</option>
                    <option value="07H30">07H30</option>
                    <option value="08H00">08H00</option>
                    <option value="08H30">08H30</option>
                    <option value="09H00">09H00</option>
                    <option value="09H30">09H30</option>
                    <option value="10H00">10H00</option>
                    <option value="10H30">10H30</option>
                    <option value="11H00">11H00</option>
                    <option value="11H30">11H30</option>
                    <option value="12H00">12H00</option>
                    <option value="12H30">12H30</option>
                    <option value="13H00">13H00</option>
                    <option value="13H30">13H30</option>
                    <option value="14H00">14H00</option>
                    <option value="14H30">14H30</option>
                    <option value="15H00">15H00</option>
                    <option value="15H30">15H30</option>
                    <option value="16H00">16H00</option>
                    <option value="16H30">16H30</option>
                    <option value="17H00">17H00</option>
                    <option value="17H30">17H30</option>
                    <option value="18H00">18H00</option>
                    <option value="18H30">18H30</option>
                </select>
            </div>
            <div className="col">
                <select className="form-control"  onChange={handleFinChange} value={fin}>
                    <option value="07H00">07H00</option>
                    <option value="07H30">07H30</option>
                    <option value="08H00">08H00</option>
                    <option value="08H30">08H30</option>
                    <option value="09H00">09H00</option>
                    <option value="09H30">09H30</option>
                    <option value="10H00">10H00</option>
                    <option value="10H30">10H30</option>
                    <option value="11H00">11H00</option>
                    <option value="11H30">11H30</option>
                    <option value="12H00">12H00</option>
                    <option value="12H30">12H30</option>
                    <option value="13H00">13H00</option>
                    <option value="13H30">13H30</option>
                    <option value="14H00">14H00</option>
                    <option value="14H30">14H30</option>
                    <option value="15H00">15H00</option>
                    <option value="15H30">15H30</option>
                    <option value="16H00">16H00</option>
                    <option value="16H30">16H30</option>
                    <option value="17H00">17H00</option>
                    <option value="17H30">17H30</option>
                    <option value="18H00">18H00</option>
                    <option value="18H30">18H30</option>
                </select>
            </div>
        </div>




        
    )
}
