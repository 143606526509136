import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { navigate } from 'hookrouter';
import { connectAPI } from '../helper/api';
import htmlToDraft from 'html-to-draftjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



export const Annonce = ()=> {

    
    const [visibite, setvisibite] = useState("Caché")
    const [texte, settexte] = useState("Annonce")
    const [editorState, seteditorState] = useState(EditorState.createEmpty())
    const [spinnerVisible, setspinnerVisible] = useState("d-none")

    useEffect(() => {
        getAnnonce()
    }, [])

    const getAnnonce = async ()=>{
        let data = new FormData()
        data.append("req", "get")
        
        const result = await connectAPI("annonce.php", data)

        setvisibite(result.data.visibilite)

        const blocksFromHtml = htmlToDraft(result.data.texte)
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        seteditorState(EditorState.createWithContent(contentState))

        //setspinnerVisible("d-none")
    }

    const handleSauvegarderClic = async event =>{
        if(texte == undefined)
        {
            alert("Une erreur s'est produite.")
            //window.open()
        }
        else
        {
            if(texte.trim() == "" || texte.trim() == "<p></p>")
            {
                alert("Vous n'avez pas entré de texte")
            }
            else
            {
                if(window.confirm("Vous avex défini le message comme étant: \"" + visibite + "\"\nVoulez-vous continuer ?"))
                {
                    setspinnerVisible("d-inline")
                    let data = new FormData()
                    data.append("req", "set")
                    data.append('visibilite', visibite)
                    data.append('texte', texte)
                    
                    const result = await connectAPI("annonce.php", data)
                    setspinnerVisible("d-none")
                }
            }
        }
    }

    const handleVisibiliteChange = event =>{
        setvisibite(event.target.value)
    }

    const onEditorStateChange = (edState) => {
        seteditorState(edState)
    }

    const onContentStateChange = (ctState) => {
        settexte(draftToHtml(ctState))
    }

    if(sessionStorage.getItem("token") == null)
    {
        navigate('/')

        return null
    }
    else
    {
        return (
            <div className="container p-3">
                <div className="mt-3">
                    <h3 className="text-center">Annonce</h3>
                </div>
                <div className="row mt-3 bg-white p-5 shadow">
                    <div className="col-8 mx-auto mb-5">
                        <div className="row">
                            <div className="col">
                                <h4>Affichage de l'annonce:</h4>
                            </div>
                            <div className="col">
                                <select className="form-control" onChange={handleVisibiliteChange} value={visibite}>
                                    <option value="Caché">Caché</option>
                                    <option value="Visible">Visible</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div className="">
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            onContentStateChange={onContentStateChange}
                            localization={{
                                locale: 'fr',
                            }}
                            editorStyle={
                                { 
                                    border: "1px solid #edebeb",
                                    height: "200px" 
                                }
                            }
                        />
                    </div>
                    <div className="col-12 my-4 text-center">
                        <button className="btn primaire text-white btn-lg" onClick={handleSauvegarderClic}>Sauvegarder</button>
                    </div>
                    <div className="col-12 text-center">
                        <FontAwesomeIcon className={spinnerVisible} icon={faSpinner} spin size="2x" />
                    </div>
                </div>
                
            </div>
        )
    }
}
