import { A } from 'hookrouter'
import React from 'react'

export const Erreur = () =>{

  

    return (
        <div>
            <div className="container-fluid ">
                <div className="row pt-5">
                    <div className="col-12 col-sm-6 mx-auto bg-white shadow p-5 text-center">
                        <h1 className="grand-texte texte-primaire">404</h1>
                        <p className="texte-secondaire fs-3">Cette page n'existe pas ou a été déplacée.</p>
                        <A href="/" className="btn btn-primary">Page de connexion</A>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}