import axios from 'axios'

export const connectAPI = (lien, data) => {

    const headers = { 
        'content-type': 'application/json'
    }

    //axios.post("../../Api/functions/get_detail_search.php", formData, {headers})
    return axios.post("../../Api/" + lien, data, {headers})
        /*    .then(res => {
                console.log(res.data);
                //setListePopulaires(res.data)
            })
            .catch(erreur => {
                console.log(erreur);
            })
            */
}